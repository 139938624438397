.more-services-info-page {
  padding: 50px 0;
  background-color: #F5E9F7;
}

.more-services-info-page-header {
  padding: 3%;
  min-height: 40%;
  text-align: center;
}

.more-services-info-page-header h1 {
  color: #24253A;
  width: 70%;
  margin: 30px auto;
}

.more-services-info-page-header h3 {
  color: #24253A;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.more-services-info-page-content-container {
  padding: 60px 120px;
  display: flex;
  justify-content: center;
}

.more-services-info-page-content {
  text-align: center;
}

.more-services-info-page-content hr {
  margin: 50px auto;
}

.more-services-info-page-section {
  margin-top: 50px;
}

.more-services-info-page-section h2,
.more-services-info-page-section h5 {
  color: #24253A;
  text-align: left;
}

.react-player,
.youtube-player-container {
  position: relative;
  margin-bottom: 20px;
}

.youtube-player-container {
  padding-top: 56.25%;
}

.react-player iframe,
.youtube-player-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .more-services-info-page-content-container {
    padding: 40px 20px;
  }

  .more-services-info-page-header h1 {
    font-size: 2rem;
    width: 90%;
  }

  .more-services-info-page-header h3 {
    font-size: 1.2rem;
  }

  .more-services-info-page-section h2 {
    font-size: 1.5rem;
  }

  .more-services-info-page-section h5 {
    font-size: 1rem;
  }

  .more-services-info-page-content {
    padding: 0;
  }
}

@media (max-width: 480px) {
  .more-services-info-page-content-container {
    padding: 20px 10px;
  }

  .more-services-info-page-header h1 {
    font-size: 1.5rem;
  }

  .more-services-info-page-header h3 {
    font-size: 1rem;
  }

  .more-services-info-page-section h2 {
    font-size: 1.2rem;
  }

  .more-services-info-page-section h5 {
    font-size: 0.9rem;
  }

  .more-services-info-page-content {
    padding: 0;
  }
}
