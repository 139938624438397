.product-quick-view {
  display: flex;
  width: 100%;
  max-width: 100%;
}

.quick-view-img-container {
  position: relative;
  width: 50%;
  margin: 20px 30px;
}

.quick-view-product-carousel {
  width: 100%;
}

.carousel-image {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

/* Slick custom styles */
.slick-prev:before, .slick-next:before {
  color: #ff6600;
}

.slick-dots li button:before {
  color: #494949;
}

.slick-dots li.slick-active button:before {
  color: #ff6600;
}
/* End: Slick custom styles */

.quick-view-ribbon {
  position: absolute;
  top: 5%;
  left: 5%;
  background-color: #ED5B20;
  color: white;
  padding: 0.1rem 0.5rem;
  font-size: 0.8rem;
}

.quick-view-texts {
  width: 50%;
  padding: 30px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.quick-view-header {
  font-size: 1.5rem;
  margin-bottom: 10px;
  margin-top: 0;
  font-family: 'Merriweather', serif;
  font-weight: 500;
}

.quick-view-price {
  color: rgb(75, 75, 75);
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.quantity-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.quantity-container label {
  margin-right: 10px;
}

.quantity-input {
  width: 50px;
  padding: 5px;
  border: 1px solid #ddd;
  text-align: center;
  margin-right: 15px;
}

.quick-view-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-to-cart-button {
  font-size: 1rem;
  margin-bottom: 15px;
  width: 100%;
}

.view-details-link {
  color: rgb(75, 75, 75);
  text-decoration: none;
  font-size: 0.9rem;
  margin-top: 10px;
}

.view-details-link:hover {
  text-decoration: underline;
}

@keyframes flyToCart {
  0% {
    opacity: 1;
    transform: scale(1) translate(0, 0);
  }
  100% {
    opacity: 0;
  }
}

.fly-to-cart {
  position: fixed;
  width: 100px;
  height: 100px;
  animation: flyToCart 1s ease forwards;
  pointer-events: none;
  opacity: 1;
  z-index: 99999;
}

@media (max-width: 786px) {
  .product-quick-view {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .quick-view-img-container, .quick-view-texts {
    width: 90%;
    margin: 10px 0;
  }

  .quick-view-texts {
    padding: 20px;
  }

  .quick-view-header {
    font-size: 1.3rem;
  }

  .quick-view-price {
    font-size: 1rem;
  }

  .add-to-cart-button {
    font-size: 0.9rem;
  }
}

@media (max-width: 540px) {
  .quick-view-img-container {
    width: 95%;
    margin: 0;
  }

  .quick-view-texts {
    padding: 15px;
  }

  .quick-view-header {
    font-size: 1.1rem;
  }

  .quick-view-price {
    font-size: 0.9rem;
  }

  .add-to-cart-button {
    font-size: 0.85rem;
  }

  .quantity-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .quantity-input {
    width: 100%;
  }
}

@media (hover: none) {
  .view-details-link {
    text-decoration: underline;
  }
}
