.hero {
  background-image: url('/public/cover_img_with_overlay.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10% 20%;
}

.hero-text {
  z-index: 1;
  color: white;
}

.hero-text h1 {
  margin: 0;
  padding: 20px;
  text-align: center;
}

.hero-text p {
  font-size: 1.5rem;
  padding: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .hero {
    padding: 15% 10%;
  }

  .hero-text h1 {
    font-size: 2.5rem;
  }

  .hero-text p {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .hero {
    padding: 20% 5%;
  }

  .hero-text h1 {
    font-size: 2rem;
  }

  .hero-text p {
    font-size: 1rem;
  }
}
