.appt-confirmation-container {
  max-width: 100%;
  margin: 100px auto;
  padding: 30px;
  background: #F5E9F8;
  text-align: center;
  border-radius: 8px;
}

.appt-confirmation-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.appt-confirmation-container p {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

@media (max-width: 850px) {
  .appt-confirmation-container {
    padding: 80px;
    margin: 0 auto;
    background-color: #F5E9F8;
  }

  .appt-confirmation-container h1 {
    font-size: 2rem;
  }

  .appt-confirmation-container p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .appt-confirmation-container {
    padding: 65px;
    margin: 0 auto;
  }

  .appt-confirmation-container h1 {
    font-size: 1.5rem;
  }

  .appt-confirmation-container p {
    font-size: 1rem;
  }

  .appt-confirmation-container Button {
    font-size: 0.9rem;
  }
}
