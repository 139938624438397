.background-story-page {
  font-family: 'Merriweather', serif;
  padding: 40px 0;
}

.background-story-page-header {
  padding: 3%;
  min-height: 40%;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background-story-page-header h1 {
  color: #24253A;
  font-family: 'Playfair Display', serif;
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
}

.background-story-page-header h2 {
  font-family: 'Playfair Display', serif;
  font-size: 1.25rem;
  margin: 0;
  font-weight: 400;
}

.brand-story-container {
  padding: 60px 120px;
  position: relative;
  background-image: url('/public/brand_story_background.webp');
  background-size: cover;
  background-position: center;
  min-height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.brand-story-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.brand-story-container h3 {
  color: white;
  font-size: 1rem;
  margin: 0;
  z-index: 2;
}

.brand-story-content-container {
  position: relative;
  z-index: 2;
  color: white;
  padding: 20px;
}

.logo-background-story-container {
  display: flex;
  padding: 60px 120px;
  margin-top: 40px;
}

.logo-background-story-img-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
}

.logo-background-story-img-container img {
  width: 100%;
  height: auto;
}

.logo-background-story-content-container {
  padding: 20px;
}

.logo-background-story-text-section {
  margin-bottom: 20px;
}

.logo-background-story-text-section h4 {
  margin-bottom: 0;
}

.logo-background-story-text-section p {
  margin-top: 10px;
}

@media (max-width: 980px) {
  .background-story-page-header h1 {
    width: 90%;
    font-size: 2rem;
    margin: 20px auto;
  }

  .background-story-page-header h2 {
    font-size: 1rem;
  }

  .brand-story-container {
    padding: 40px;
    min-height: 400px;
  }

  .logo-background-story-container {
    padding: 40px;
  }
}

@media (max-width: 780px) {
  .background-story-page-header h1 {
    width: 90%;
    font-size: 1.5rem;
    margin: 20px auto;
  }

  .brand-story-container {
    padding: 15px;
  }

  .logo-background-story-container {
    flex-direction: column;
  }

  .logo-background-story-img-container {
    width: 100%;
    margin-right: 0;
  }

  .logo-background-story-content-container {
    width: 100%;
  }

  .logo-background-story-content-container h3 {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 30px;
  }
}

@media (max-width: 540px) {
  .background-story-page {
    padding: 20px 0;
  }

  .background-story-page-header {
    margin-bottom: 0;
  }

  .background-story-page-header h1 {
    width: 100%;
    font-size: 1.1rem;
    margin: 10px auto;
  }

  .background-story-page-header h2 {
    font-size: 0.9rem;
  }

  .brand-story-container {
    padding: 5px;
  }

  .brand-story-content-container {
    padding: 10px 20px;
  }

  .brand-story-content-container h4 {
    font-size: 1rem;
  }

  .logo-background-story-container {
    flex-direction: column;
    padding: 20px;
  }

  .logo-background-story-img-container {
    width: 100%;
    margin-right: 0;
  }

  .logo-background-story-content-container {
    width: 100%;
    padding: 10px;
  }

  .logo-background-story-content-container h3 {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 30px;
  }

  .logo-background-story-text-section h4 {
    font-size: 1rem;
  }
}

