.service-card {
  display: flex;
  transition: transform 0.3s ease;
  width: 100%;
  max-width: 1000px;
  min-height: 200px;
  align-items: flex-start;
  margin: 7% 0;
  padding-left: 16%;;
}

.service-card.image-right .service-card-image {
  order: 2; /* Show image on the right for even-numbered cards */
  transform: translateX(-30%);
}

.service-card.image-left .service-card-content {
  order: 1; /* Show image on the left for odd-numbered cards */
  transform: translateX(-30%);
}

.service-card:hover {
  transform: translateY(-5px);
  cursor: pointer;
}

.service-card-image {
  width: 70%;
}

.service-card-image img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 400px;
}

.service-card-content {
  padding: 60px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  bottom: -10%;
  background-color: white;
  align-items: start;
  width: 60%;
  z-index: 1;
}

.service-card-content h3 {
  margin: 0;
  font-size: 1.8rem;
  color: #333;
  font-weight: 400;
  text-align: left;
}

.service-card-content p {
  margin: 20px 0;
  font-size: 1rem;
  color: #666;
  text-align: left;
}

.service-card-divider {
  width: 100%;
  border-top: #D2D2D2;
}

.service-card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
}

.service-card-info p {
  font-size: 1rem;
  margin: 5px 0;
}

.read-more {
  background-color: transparent;
  border: none;
  color: #777;
  cursor: pointer;
  font-weight: 100;
  text-decoration: underline;
  padding: 0;
  transition: transform 0.3s ease;
}

.read-more:hover {
  font-weight: bold;
}

.service-card-description-details {
  text-align: left;
}

@media (max-width: 786px) {
  .service-card {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    margin: 0;
  }

  .service-card-content {
    position: static;
    background: white;
    padding: 40px 20px;
    width: 80%;
  }

  .service-card-image {
    order: 2;
    width: 50%;
    z-index: 99;
  }

  .service-card.image-right .service-card-image,
  .service-card.image-left .service-card-image {
    transform: translate(90%, -70%);
  }

  .service-card.image-left .service-card-content {
    transform: none;
  }

  .service-card-image img {
    max-height: 250px;
  }

  .service-card-content h3 {
    font-size: 1.6rem;
  }

  .service-card-content p {
    font-size: 1rem;
  }

  .service-card-info p {
    width: 50%;
    text-wrap: wrap;
  }

  .book-now {
    width: 40%;
  }
}

@media (max-width: 560px) {
  .service-card {
    flex-direction: column;
    align-items: center;
    margin: 5% 0;
    padding-left: 0;
  }

  .service-card-image {
    width: 100%;
    order: 1;
  }

  .service-card-image img {
    width: 100%;
    height: auto;
  }

  .service-card-content {
    width: 100%;
    padding: 30px 10px;
    order: 2;
    box-sizing: border-box;
  }

  .service-card.image-right .service-card-image,
  .service-card.image-left .service-card-image {
    transform: none;
    display: flex;
  }

  .service-card-content h3 {
    font-size: 1.2rem;
  }

  .service-card-content p {
    font-size: 0.9rem;
  }

  .service-card-info p {
    width: 100%;
    text-wrap: wrap;
  }

  .book-now {
    width: 100%;
  }
}

@media (hover: none) {
  .service-card {
    transform: none;
    cursor: default;
  }

  .read-more {
    font-weight: normal;
  }
}
