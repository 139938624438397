.iching-cards-instructions-page {
  padding: 50px 0;
  background-color: #EAEAEA;
}

.iching-cards-instructions-page-header {
  background-image: url('/public/I Ching Cards crop header.jpg');
  color: white;
  padding: 3%;
  min-height: 40%;
  text-align: center;
}

.iching-cards-instructions-page-header h1 {
  background-color: #EFE5D8;
  color: #24253A;
  width: 60%;
  margin: 30px auto;
}

.iching-cards-instructions-page-section:first-child {
  margin-top: 20px;
}

.iching-cards-instructions-page-section {
  padding: 30px;
  margin: 10px 0;
  display: flex;
}

.iching-cards-instructions-page-text-column, .iching-cards-instructions-page-text {
  width: 50%;
  padding: 30px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.iching-cards-instructions-page-video-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  flex: 1 1 auto;
}

.iching-cards-instructions-page-text h4 {
  margin-bottom: 5px;
}

.iching-cards-instructions-page-card {
  min-width: 55px;
  max-width: 55px;
  min-height: 75px;
  max-height: 75px;
  text-align: center;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.vertical-writing {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.iching-card-spread-cards-container.grid-flex {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.iching-card-spread-container {
  padding: 30px;
  margin: 10px 0;
}

.iching-card-spread-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.iching-card-spread-grid {
  background: transparent;
  padding: 20px;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.iching-card-spread-grid ul, .iching-card-spread-grid p {
  margin: 5px 3px;
}

.iching-card-spread-cards-container {
  display: flex;
  margin: 20px 0;
}

.iching-card-spread-section-container ul {
  width: 70%;
}

.iching-card-spread-section-container ul ul {
  list-style: none;
}

.iching-card-spread-section-container img {
  max-width: 90%;
}

.iching-cards-instructions-page-next-steps a {
  color: #ED5B20;
}

.react-player,
.youtube-player-container {
  position: relative;
  margin-bottom: 20px;
}

.youtube-player-container {
  padding-top: 56.25%;
}

.react-player iframe,
.youtube-player-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1100px) {
  .iching-cards-instructions-page-header h1 {
    width: 80%;
    font-size: 1.8rem;
  }

  .iching-cards-instructions-page-section {
    flex-direction: column;
    padding: 20px;
  }

  .iching-cards-instructions-page-text-column,
  .iching-cards-instructions-page-video-column {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .iching-cards-instructions-page-video-column {
    order: 2;
  }

  .iching-cards-instructions-page-text-column {
    order: 1;
  }

  .iching-card-spread-section-container ul {
    width: 100%;
  }

  .iching-card-spread-grid > img {
    width: 400px;
    margin: 0 auto;
  }

  .iching-card-spread-cards-container.grid-flex {
    width: 400px;
  }

  .fengshui-method-cards-container {
    margin: 0 auto;
  }

  .fengshui-method-cards-container span {
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .iching-cards-instructions-page-header {
    padding: 5%;
  }

  .iching-cards-instructions-page-header h1 {
    width: 90%;
    font-size: 1.5rem;
    margin: 20px auto;
  }

  .iching-cards-instructions-page-section {
    padding: 15px;
    margin: 5px 0;
  }

  .iching-cards-instructions-page-text-column,
  .iching-cards-instructions-page-video-column {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }

  .iching-cards-instructions-page-video-column {
    order: 2;
  }

  .iching-cards-instructions-page-text-column {
    order: 1;
  }

  .iching-card-spread-section-container ul {
    width: 100%;
  }

  .iching-cards-instructions-page-card {
    min-width: 40px;
    max-width: 40px;
    min-height: 60px;
    max-height: 60px;
  }

  .iching-card-spread-cards-container.grid-flex {
    gap: 10px;
  }
}

@media (max-width: 540px) {
  .iching-cards-instructions-page-header {
    padding: 5%;
  }

  .iching-cards-instructions-page-header h1 {
    width: 90%;
    font-size: 1.5rem;
    margin: 20px auto;
  }

  .iching-cards-instructions-page-section {
    padding: 15px;
    margin: 5px 0;
    align-items: center;
  }

  .iching-cards-instructions-page-text-column,
  .iching-cards-instructions-page-video-column {
    width: 95%;
    padding: 15px;
    box-sizing: border-box;
  }

  .iching-cards-instructions-page-video-column {
    order: 2;
  }

  .iching-cards-instructions-page-text-column {
    order: 1;
  }

  .iching-card-spread-section-container ul {
    width: 95%;
  }

  .iching-cards-instructions-page-card {
    min-width: 40px;
    max-width: 40px;
    min-height: 60px;
    max-height: 60px;
  }

  .iching-card-spread-grid > img {
    width: 300px;
    margin: 0 auto;
  }

  .iching-card-spread-cards-container.grid-flex {
    width: 300px;
    gap: 10px;
  }
}
