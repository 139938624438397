.header-section {
  position: relative;
  text-align: center;
  background-image: url('/public/energy_reform_landscape.webp');
  height: 300px;
  overflow: hidden;
  margin-bottom: 40px;
}

.header-content {
  position: absolute;
  top: 70%;
  left: 35%;
  transform: translate(-50%, -50%);
  background-color: white;
  color: #777;
  padding: 1% 3%;
  height: 40%;
  text-align: left;
}

.header-content h1 {
  font-size: 2rem;
  margin: 0;
  color: black;
}

.header-content p {
  font-size: 0.9rem;
  margin: 0;
  font-family: 'Lato', sans-serif;
}

@media (max-width: 768px) {
  .header-section {
    height: 250px;
  }

  .header-content h1 {
    font-size: 1.6rem;
  }

  .header-content p {
    font-size: 0.8rem;
  }
}

@media (max-width: 540px) {
  .header-section {
    height: 200px;
  }

  .header-content {
    top: 54%;
    height: 60%;
  }

  .header-content h1 {
    font-size: 1.2rem;
  }

  .header-content p {
    font-size: 0.7rem;
  }
}
