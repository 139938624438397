.about-us {
  padding: 50px;
  background-color: #f9f9f9;
}

.about-us h2 {
  font-size: 3rem;
  margin-bottom: 90px;
}

.about-content {
  display: flex;
  align-items: flex-start;
  margin: 50px;
  justify-content: space-evenly;
}

.about-content img {
  width: 300px;
  margin-right: 100px;
  align-self: flex-start;
}

.text-content {
  max-width: 700px;
}

.text-content h3 {
  font-weight: 100;
  margin-bottom: 20px;
  font-family: 'Avenir', sans-serif;
  margin-top: 0;
}

.text-content p {
  font-size: 1.4rem;
  margin: 20px 0;
  font-family: 'Playfair Display', serif;
  letter-spacing: 1px;
  font-weight: bold;
}

@media (max-width: 880px) {
  .about-us {
    padding: 30px;
  }

  .about-us h2 {
    font-size: 2.5rem;
  }

  .about-content {
    flex-direction: column;
    margin: 30px;
  }

  .about-content img {
    margin: 0 auto 20px;
    width: 250px;
  }

  .text-content {
    text-align: center;
    margin: 0 auto;
  }
}

@media (max-width: 540px) {
  .about-us {
    padding: 20px;
  }

  .about-us h2 {
    font-size: 2rem;
  }

  .about-content {
    margin: 20px;
  }

  .about-content img {
    width: 200px;
  }

  .text-content p {
    font-size: 1rem;
  }
}
