.language-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #837FBB;
  border-radius: 5px 0 0 0;
  padding: 10px 5px;
  z-index: 9999;
}

.language-switcher ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
  display: flex;
}

.language-switcher li {
  cursor: pointer;
}

.language-switcher li:nth-child(2) {
  border-left: 1px solid rgb(255, 255, 255, 0.5);
}

.language-switcher li:first-child{
  border-right: 1px solid rgb(255, 255, 255, 0.5);
}

.language-switcher .unselected {
  opacity: 0.3;
}

.language-switcher button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  padding: 0 10px;
}
