.products-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 60px;
  padding: 20px;
  width: 80%;
}

.products-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.products-count {
  align-self: start;
  padding-left: 6%;
  font-size: 0.9rem;
  font-weight: 400;
}

@media (max-width: 840px) {
  .products-list {
    width: 90%;
    gap: 40px;
  }

  .products-count {
    padding-left: 4%;
    font-size: 0.85rem;
  }
}

@media (max-width: 730px) {
  .products-list {
    width: 100%;
    padding: 10px;
    gap: 30px;
    box-sizing: border-box;
  }

  .products-count {
    font-size: 0.8rem;
    padding-left: 3%;
  }
}
