.products-page {
  background-color: #EAEAEA;
  padding: 100px 0;
}

@media (max-width: 768px) {
  .products-page {
    padding: 80px 0;
  }
}

@media (max-width: 480px) {
  .products-page {
    padding: 60px 0;
  }
}
