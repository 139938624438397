.testimonials-page-header {
  background-image: url('/public/testimonials_background.png');
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.testimonials-page-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 100px;
  gap: 60px;
  margin: 50px 0;
}

.testimonial-grid-img-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.testimonial-grid-img-container img {
  width: 20%;
  height: 20%;
  flex: 0 0 auto;
}

.testimonial-grid-content {
  padding: 10px 30px;
  text-align: center;
  margin-bottom: 50px;
}

.testimonial-grid-content p {
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 1100px) {
  .testimonials-page-header {
    height: 300px;
  }

  .testimonials-page-list {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 50px;
  }

  .testimonial-grid-img-container {
    margin-bottom: 10px;
  }

  .testimonials-page-header h1 {
    font-size: 2.5rem;
  }

  .testimonial-grid-content {
    margin-bottom: 30px;
  }

  .testimonial-grid-content p {
    width: 90%;
  }
}

@media (max-width: 750px) {
  .testimonials-page-header {
    height: 250px;
  }

  .testimonials-page-list {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 30px;
  }

  .testimonials-page-header h1 {
    font-size: 2rem;
  }

  .testimonials-page-header h4 {
    font-size: 1.3rem;
  }
}

@media (max-width: 540px) {
  .testimonials-page-header {
    height: 200px;
  }

  .testimonials-page-list {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 20px;
    margin: 20px 0;
  }

  .testimonials-page-header h1 {
    font-size: 1.3rem;
  }

  .testimonials-page-header h4 {
    font-size: 0.9rem;
    width: 90%;
    text-align: center;
  }

  .testimonial-grid-img-container {
    margin-bottom: 0;
  }

  .testimonial-grid-content {
    padding: 0;
    margin-bottom: 10px;
  }

  .testimonial-grid-content p {
    width: 100%;
  }
}
