.testimonials {
  padding: 50px;
  background-color: #eaeaea;
}

.testimonials-header h2 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.testimonials-header p {
  font-size: 1.2rem;
  margin-bottom: 40px;
  width: 60%;
}

.testimonials-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.testimonial {
  width: 95%;
  padding: 20px;
  margin-bottom: 20px;
  text-align: right;
}

.testimonial-divider {
  height: 1px;
  background: black;
  color: black;
  border: none;
}

.testimonial-content {
  display: flex;
  justify-content: end;
}

.testimonial-content h4 {
  margin-top: 15px;
  font-size: 1.5rem;
  color: #333;
  width: 20%;
  text-align: left;
}

.testimonial-content p {
  font-size: 1.2rem;
  color: #555;
  width: 40%;
  padding: 0 20px;
  align-self: center;
  text-align: left;
}

@media (max-width: 880px) {
  .testimonials {
    padding: 30px;
  }

  .testimonials-header {
    width: 80%;
  }

  .testimonials-header h2 {
    font-size: 2rem;
  }

  .testimonials-header p {
    font-size: 1rem;
    width: 90%;
  }

  .testimonial-content {
    justify-content: space-evenly;
  }

  .testimonial-content h4 {
    font-size: 1.2rem;
  }

  .testimonial-content p {
    font-size: 1rem;
    width: 50%;
  }
}

@media (max-width: 540px) {
  .testimonials {
    padding: 20px;
  }

  .testimonials-header {
    width: 90%;
  }

  .testimonials-header h2 {
    font-size: 1.7rem;
  }

  .testimonials-header p {
    font-size: 0.9rem;
    width: 95%;
  }

  .testimonial-content {
    justify-content: space-evenly;
  }

  .testimonial-content h4 {
    font-size: 1rem;
  }

  .testimonial-content p {
    font-size: 0.9rem;
    width: 70%;
  }
}
