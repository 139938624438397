.class-card {
  width: 300px;
  max-width: 400px;
  background: transparent;
  padding: 20px;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease;
}

.class-card:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.class-card img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
}

.class-card h3 {
  font-size: 1.5rem;
  margin-top: 15px;
}

.class-card-divider {
  margin: 20px 0;
  color: #CECECE;
}

.class-card p {
  font-size: 1rem;
  margin: 10px 0;
}

.class-card-pricing {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.class-card-button {
  align-self: flex-start;
}

.class-card-button.display-on-hover {
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.class-card:hover .class-card-button.display-on-hover {
  opacity: 1;
}

@media (max-width: 880px) {
  .class-card {
    flex-direction: column;
    margin: 0 auto;
  }

  .class-card img {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }

  .class-card-content {
    text-align: center;
    padding: 20px;
  }

  .class-card h3 {
    font-size: 1.2rem;
  }

  .class-card p {
    font-size: 1rem;
  }

  .class-card-button {
    width: 70%;
    align-self: center;
  }
}

@media (max-width: 540px) {
  .class-card h3 {
    font-size: 1rem;
  }

  .class-card p {
    font-size: 0.9rem;
  }

  .class-card-button {
    width: 80%;
    align-self: center;
  }
}

@media (hover: none) {
  .class-card {
    transform: none;
    cursor: default;
  }

  .class-card .class-card-button.display-on-hover {
    opacity: 1;
  }
}
