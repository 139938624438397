.singing-bowls-section {
  width: 70%;
  margin: 0 auto;
  padding: 0 50px;
}

.react-player {
  position: relative;
  margin-bottom: 20px;
}

.react-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
