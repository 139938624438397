.products-section {
  padding: 50px;
  background-color: #eaeaea;
  text-align: center;
}

.products-header {
  width: 60%;
  margin: 0 auto;
}

.products-header h2 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.products-header p {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.products {
  display: flex;
  justify-content: space-around;
  margin: 40px 0;
}

.product-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 80px;
  justify-content: center;
  margin-bottom: 40px;
}

@media (max-width: 1150px) {
  .product-grid-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    gap: 40px;
  }
}

@media (max-width: 880px) {
  .products-header {
    width: 80%;
  }

  .products-header h2 {
    font-size: 2.5rem;
  }

  .products-header p {
    font-size: 1rem;
  }

  .load-more-btn {
    width: 30%;
  }
}

@media (max-width: 730px) {
  .product-grid-container {
    grid-template-columns: 1fr; /* 1 column */
  }
}

@media (max-width: 540px) {
  .products-header {
    width: 90%;
  }

  .products-header h2 {
    font-size: 2rem;
  }

  .products-header p {
    font-size: 0.9rem;
  }

  .load-more-btn {
    width: 50%;
  }
}
