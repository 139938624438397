.footer {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #FAFAFA;
  justify-content: center;
  flex-wrap: wrap;
}

.footer-logo {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.footer-title {
  font-size: 1.3rem;
  color: #333;
}

.footer-subtitle {
  display: block;
  margin-top: 10px;
  color: #333;
  font-size: 0.7rem;
}

@media (max-width: 768px) {
  .footer {
    text-align: center;
  }

  .footer-logo {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }

  .footer-title {
    font-size: 1.2rem;
  }

  .footer-subtitle {
    font-size: 0.6rem;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 15px;
    flex-direction: column;
  }

  .footer-logo {
    width: 35px;
    height: 35px;
    margin-bottom: 8px;
  }

  .footer-title {
    font-size: 0.9rem;
  }

  .footer-subtitle {
    font-size: 0.6rem;
  }
}
