.product {
  text-align: center;
  width: 300px;
  max-width: 400px;
  border: 1px solid #ccc;
  padding: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-bottom: 20px;
}

.product-image-container {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
}

.product-image-container img {
  width: 100%;
  transition: transform 0.5s ease;
}

.product-image.zoom-in {
  transform: scale(1.1); /* Image zoom effect */
}

.product-ribbon {
  position: absolute;
  top: 5%;
  left: 5%;
  background-color: #ED5B20;
  color: white;
  padding: 0.1rem 0.5rem;
  font-size: 0.8rem;
}

.product h3 {
  font-size: 1rem;
  margin-top: 10px;
  font-family: 'Avenir', sans-serif;
}

.product-grid-divider {
  margin: 20px 0;
  width: 20%;
  margin: 0 auto;
}

.product-price {
  color: #777;
}

.product-button {
  align-self: center;
}

.quick-view-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(256, 256, 256, 0.5);
  padding: 5% 0;
  transform: translateY(10px);
  color: black;
  font-size: 0.9rem;
}

.quick-view-banner, .product-button.display-on-hover {
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.product:hover .quick-view-banner,
.product:hover .product-button.display-on-hover {
    opacity: 1;
    transform: translateY(0);
}

@keyframes flyToCart {
  0% {
    opacity: 1;
    transform: scale(1) translate(0, 0);
  }
  100% {
    opacity: 0;
  }
}

.fly-to-cart {
  position: fixed;
  width: 100px;
  height: 100px;
  animation: flyToCart 1s ease forwards;
  pointer-events: none;
  opacity: 1;
  z-index: 99999;
}


@media (max-width: 880px) {
  .product {
    width: 250px;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .product-image-container {
    height: 220px;
  }

  .product h3 {
    font-size: 0.9rem;
  }

  .product-grid-divider {
    width: 30%;
  }

  .product-price {
    font-size: 0.9rem;
  }

  .quick-view-banner {
    font-size: 0.8rem;
  }

  .product-button {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .product {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }

  .product-image-container {
    height: 180px;
  }

  .product h3 {
    font-size: 0.8rem;
  }

  .product-grid-divider {
    width: 40%;
  }

  .product-price {
    font-size: 0.8rem;
  }

  .quick-view-banner {
    font-size: 0.7rem;
  }

  .product-button {
    font-size: 0.8rem;
    width: 70%;
  }
}

@media (hover: none) {
  .product .product-button.display-on-hover {
    opacity: 1;
    transform: none;
  }

  .product:hover .quick-view-banner {
    opacity: 0;
    transform: none;
  }
}
