.services {
  padding: 50px;
  background-color: #f5e9f7;
  text-align: left;
}

.services h2 {
  font-size: 3rem;
  margin-bottom: 40px;
}

.service-list {
  display: flex;
  flex-direction: column;
  padding: 0 5%;
}

.service {
  text-align: center;
  margin-bottom: 40px;
  border: 1px solid #ccc;
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.service:hover {
  transform: translateY(-5px);
  cursor: pointer;
}

.service img {
  width: 40%;
  min-height: 100%;
}

.service-content {
  padding: 20px 40px;
  margin: auto 0;
  text-align: left;
}

.service-content h3 {
  font-size: 1.5rem;
  margin-top: 10px;
}

.service-content h5 {
  color: #494949;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
  font-family: 'Avenir', sans-serif;
  font-weight: 400;
}

.read-more-btn {
  background-color: transparent;
  border: none;
  color: #777;
  cursor: pointer;
  font-weight: 100;
  text-decoration: underline;
  padding: 0;
}

.read-more-btn:hover {
  font-weight: bold;
}

.service-content p {
  font-size: 1.2rem;
  color: #494949;
}

.service-divider {
  color: #CECECE;
}

.service-button {
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.service:hover .service-button {
  opacity: 1;
}

@media (max-width: 880px) {
  .service {
    flex-direction: column;
  }

  .service img {
    width: 100%;
  }

  .service-content {
    text-align: center;
    padding: 20px;
  }

  .services h2 {
    font-size: 2.5rem;
  }

  .service-content h3 {
    font-size: 1.2rem;
  }
  
  .service-content h5 {
    font-size: 0.8rem;
  }

  .service-content p {
    font-size: 1.1rem;
  }

  .service-button {
    width: 70%;
  }
}

@media (max-width: 540px) {
  .services h2 {
    font-size: 2rem;
  }

  .service-list {
    padding: 0;
  }

  .service-content p {
    font-size: 1rem;
  }

  .service-button {
    width: 80%;
  }
}

@media (hover: none)  {
  .service {
    transform: none;
    cursor: default;
  }

  .read-more-btn {
    font-weight: normal;
  }

  .service .service-button {
    opacity: 1;
  }
}
