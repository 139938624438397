.shopping-cart-icon {
  color: #837FBB;
  position: absolute;
}

.shopping-cart-icon:hover {
  cursor: pointer;
}

.cart-badge {
  position: absolute;
  top: 15%;
  right: 3%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-badge > .shopping-cart-icon {
  width: 100%;
  height: 100%;
}

.cart-badge::after {
  content: attr(data-count);
  position: absolute;
  top: -13px;
  right: -13px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px 10px;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .cart-badge {
    top: 20px;
    right: 80px;
  }
}