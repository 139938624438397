.subconscious-integration-section {
  width: 70%;
  margin: 0 auto;
  padding: 0 50px;
}

.subconscious-integration-sub-section:last-child {
  margin-top: 50px;
}

.youtube-player-container {
  position: relative;
  margin-bottom: 20px;
  padding-top: 56.25%;
}

.youtube-player-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .subconscious-integration-section {
    width: 90%;
    padding: 0 20px;
  }

  .subconscious-integration-sub-section {
    width: 100%;
  }

  .subconscious-integration-header {
    font-size: 1.5rem;
  }

  .subconscious-integration-sub-section p {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .subconscious-integration-section {
    width: 90%;
    padding: 0 10px;
  }

  .subconscious-integration-header {
    font-size: 1.2rem;
  }

  .subconscious-integration-sub-section p {
    font-size: 0.85rem;
  }

  .subconscious-integration-sub-section {
    margin-bottom: 30px;
  }
}
