.quick-view-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  cursor: auto;
  transition: opacity 0.5s ease;
}

.quick-view-content {
  background-color: #fff;
  padding: 50px;
  flex: 0 0 auto;
  max-width: 60%;
  max-height: 75%;
  position: relative;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  display: flex;
  animation: modalFadeIn 0.5s ease;
  overflow: auto;
}

@keyframes modalFadeIn {
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 1.5rem;
  cursor: pointer;
}

@media (max-width: 980px) {
  .quick-view-content {
    max-width: 80%;
    padding: 30px;
    max-height: 80%;
  }

  .close-modal {
    top: 8px;
    right: 15px;
  }
}

@media (max-width: 730px) {
  .quick-view-content {
    max-width: 90%;
    padding: 20px;
    max-height: 80%;
    box-sizing: border-box;
  }

  .close-modal {
    font-size: 1.3rem;
    top: 5px;
    right: 10px;
  }
}
