*,
*::before,
*::after {
  box-sizing: border-box;
}

.checkout-container {
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 100%;
}

.checkout-content {
  display: flex;
  justify-content: space-between;
}

.checkout-details {
  margin-right: 20px;
  flex: 1 1 60%;
}

.checkout-form {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.checkout-field {
  margin-bottom: 15px;
}

.checkout-field label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.checkout-field input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: 0;
  font-family: 'Lato', sans-serif;
}

.checkout-divider {
  margin: 40px 0;
  color: #CECECE;
}

.order-summary {
  padding: 0 15px;
  background: #F5E8F7;
  border-radius: 4px;
  flex: 1 1 40%;
  height: fit-content;
}

.order-summary li {
  margin-bottom: 10px;
}

.order-summary li > p {
  margin-bottom: 0;
}

.order-summary-item-details {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}

.order-summary-total-amount {
  font-size: 1.2rem;
}

.confirm-order-btn {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  background: #ff6600;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;
}

.confirm-order-btn svg {
  color: white;
}

@media (max-width: 768px) {
  .checkout-container {
    padding: 10px;
    margin: 10px auto;
  }

  .checkout-content {
    flex-direction: column;
  }

  .checkout-details, .order-summary {
    width: 100%;
    margin: 0;
  }

  .order-summary li {
    font-size: 0.9rem;
  }

  .order-summary-total-amount {
    font-size: 1.1rem;
  }

  .confirm-order-btn {
    padding: 10px;
    font-size: 0.9rem;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .checkout-container {
    margin: 0 auto;
  }

  .checkout-details h2 {
    font-size: 1.2rem;
  }

  .checkout-field label {
    font-size: 0.9rem;
  }

  .checkout-field input {
    padding: 8px;
  }

  .confirm-order-btn {
    padding: 8px;
  }
}
