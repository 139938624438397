.button {
  background-color: #ED5B20;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.button:hover {
  background-color: darkorange;
}

.button-small {
  padding: 10px 15px;
  font-size: 0.8rem;
}

.button-medium {
  padding: 16px 25px;
  font-size: 1rem;
}

.button-large {
  padding: 20px 30px;
  font-size: 1.5rem;
}

@media (hover: none) {
  .button {
    background-color: #ED5B20;
  }
}