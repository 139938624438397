.header {
  text-align: center;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #FAFAFA;
  z-index: 1000;
  transition: top 0.6s ease; /* Smooth transition for hiding and showing */
}

.logo {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  margin: 10px;
}

.logo img {
  width: 60px;
  margin: 0 10px;
}

.logo img:hover {
  cursor: pointer;
}

.logo h2 {
  flex: 0 0 auto;
  margin: 0;
  font-weight: 700;
  font-size: 1.5rem;

  /* Below is for the gradient effect of the logo */
  background: linear-gradient(45deg, #e66465, #9198e5);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  letter-spacing: 2px;
  /* End of gradient effect */
}

.hamburger-menu {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.hamburger-menu .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
  transition: 0.4s;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

.navbar {
  display: flex;
  justify-content: space-around;
  background-color: #FAFAFA;
  padding: 10px;
  width: 100%;
}

.header-hidden {
  top: -500px; /* Hide navbar by moving it out of view */
}

.header-visible {
  top: 0; /* Show navbar by moving it back into view */
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navbar li {
  display: inline;
}

.navbar a {
  text-decoration: none;
  padding: 14px 20px;
  color: #333;
}

.navbar a:hover {
  color: #e66465;
  font-weight: bold;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #FAFAFA;
  max-width: 160px;
  text-wrap: wrap;
  padding: 12px 16px;
  z-index: 1;
  color: #333;
  border-radius: 6px;
  padding: 14px 20px;
  margin: 8px 0 0 15px;
  font-size: 0.9rem;
}

.dropdown-content ul {
  flex-direction: column;
  align-items: flex-start;
}

.dropdown-content li {
  width: 100%;
  text-align: left;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.dropdown-content li:last-child {
  border-bottom: none;
}

.dropdown-content li a {
  padding: 10px 10px 10px 0;
}

.nav-item-expandable:hover .dropdown-content {
  display: block;
}

.dropdown-content ul:hover {
  cursor: pointer;
}

.dropdown-content li:hover, .dropdown-content li a:hover {
  color: #e66465;
  font-weight: 400;
}

.hamburger-menu-close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    background-color: #FAFAFA;
    padding: 40px 20px;
    max-width: 80%;
    min-width: 50%;
    border-radius: 10px 0 0 10px;
  }

  .navbar-open {
    display: flex;
  }

  .navbar ul {
    flex-direction: column;
    width: 100%;
  }

  .navbar li {
    width: 100%;
    text-align: left;
  }

  .dropdown-content {
    position: static;
  }

  .hamburger-menu {
    display: block;
  }

  .logo h2 {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .logo {
    flex-direction: column;
    align-items: flex-start;
  }

  .logo h2 {
    font-size: 1.2rem;
    text-align: left;
    width: 70%;
  }
}

.header-temporary {
  top: 0 !important;
  opacity: 1;
  transition: opacity 0.6s ease, top 0.6s ease;
}
