.iching-cards-info-header {
  margin-top: 80px;
}

/* Responsive Video Styles */
.react-player,
.youtube-player-container {
  position: relative;
  margin-bottom: 20px;
}

.youtube-player-container {
  padding-top: 56.25%;
}

.react-player iframe,
.youtube-player-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .iching-cards-info-header {
    font-size: 1.5rem;
  }

  .iching-cards-info-section p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .iching-cards-info-section {
    padding: 10px;
  }

  .iching-cards-info-header {
    font-size: 1.2rem;
    margin-top: 30px;
  }

  .iching-cards-info-section p {
    font-size: 0.9rem;
    text-align: justify;
  }
}
