.services-container {
  padding: 40px;
  background-color: #F5E9F7;
  text-align: center;
}

.services-container h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 40px;
}

.services-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px;
  justify-items: center;
}

.service-details-view-container {
  display: flex;
  flex-direction: column;
}

.service-details-view-container > div:last-child {
  margin-bottom: 100px;
}

@media (max-width: 786px) {
  .services-grid {
    gap: 30px;
  }

  .services-container h1 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .services-grid {
    gap: 30px;
  }

  .services-container h1 {
    font-size: 1.6rem;
  }
}
