.product-details-container {
  margin: 0 auto;
  max-width: 900px;
  padding: 100px 50px;
}

.product-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.product-carousel {
  width: 35%;
}

.carousel-image, .product-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: center;
}

/* Custom slick styling */
.slick-prev:before, .slick-next:before {
  color: #ff6600;
}

.slick-dots li button:before {
  color: #494949;
}

.slick-dots li.slick-active button:before {
  color: #ff6600;
}
/* End: Custom slick styling */

.product-info {
  width: 50%;
  margin: 0 50px;
}

.product-info h1 {
  font-size: 1.5rem;
  margin: 0 10px 20px 10px;
  font-weight: 200;
}

.product-info p {
  font-size: 1.3rem;
  font-weight: 100;
  margin: 20px 10px;
}

.product-purchase {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
}

.product-quantity {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.product-quantity label {
  margin: 10px 0 5px 0;
}

.product-quantity input {
  width: 50px;
  padding: 5px;
  border: 1px solid #ddd;
  text-align: left;
  margin-right: 15px;
}

.add-to-cart-btn {
  background-color: #ff6600;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 15px;
  width: 100%;
}

.product-description {
  margin-top: 40px;
}

.product-tabs {
  border-top: 1px solid #ddd;
  display: flex;
  padding-top: 20px;
}

.tab {
  padding: 20px 0;
  padding: 20px;
  width: 50%;
}

.tab:nth-child(1) {
  border-right: 1px solid #ddd;
  padding-right: 40px;
  padding-bottom: 20px;
  margin: 0;
}

.tab:nth-child(1) h3 {
  padding-right: 20px;
}

.tab h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  padding: 0 10px;
}

.tab p, .tab ul {
  font-size: 1rem;
  line-height: 1.5;
  padding: 0 10px;
}

.product-info-tab {
  width: 70%;
}

@keyframes flyToCart {
  0% {
    opacity: 1;
    transform: scale(1) translate(0, 0);
  }
  100% {
    opacity: 0;
  }
}

.fly-to-cart {
  position: fixed;
  width: 100px;
  height: 100px;
  animation: flyToCart 1s ease forwards;
  pointer-events: none;
  opacity: 1;
  z-index: 99999;
}

@media (max-width: 820px) {
  .product-details-container {
    max-width: 90%;
    padding: 50px 20px;
  }

  .product-header {
    flex-direction: column;
  }

  .product-carousel {
    width: 90%;
    margin: 0 auto 30px auto;
  }

  .product-image {
    width: 100%;
    margin-bottom: 20px;
  }

  .product-info {
    width: 90%;
    margin: 30px;
  }

  .product-info h1 {
    font-size: 1.2rem;
  }

  .product-info p, .product-quantity {
    font-size: 1rem;
  }

  .product-tabs {
    flex-direction: column;
  }

  .tab:nth-child(1) {
    border-bottom: 1px solid #ddd;
    border-right: none;
    margin: 0;
  }

  .tab h3 {
    font-size: 1.2rem;
  }

  .tab p {
    font-size: 1rem;
  }

  .product-info-tab {
    width: 90%;
    padding-right: 20px;
  }

  .tab {
    width: 90%;
  }
}

@media (max-width: 540px) {
  .product-details-container {
    max-width: 90%;
    padding: 30px 10px;
  }

  .product-header {
    flex-direction: column;
  }

  .product-carousel {
    width: 90%;
    margin: 0 auto 20px auto;
  }

  .product-image {
    width: 100%;
    margin-bottom: 20px;
  }

  .product-info {
    width: 90%;
    margin: 10px;
  }

  .product-info h1 {
    font-size: 1rem;
  }

  .product-info p, .product-quantity {
    font-size: 0.9rem;
  }

  .product-quantity input {
    width: 40px;
  }

  .add-to-cart-btn {
    font-size: 0.9rem;
  }

  .product-tabs {
    flex-direction: column;
  }

  .tab:nth-child(1) {
    border-bottom: 1px solid #ddd;
    border-right: none;
    margin: 0;
  }

  .tab h3 {
    font-size: 1rem;
  }

  .tab p {
    font-size: 0.9rem;
  }

  .product-info-tab {
    width: 95%;
  }

  .tab {
    width: 95%;
    padding: 10px;
  }
}
