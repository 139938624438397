.about-us-page {
  font-family: 'Merriweather', serif;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-us-header {
  text-align: center;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-us-header h2 {
  font-family: 'Playfair Display', serif;
  font-size: 1.25rem;
  margin: 0;
  font-weight: 400;
}

.about-us-header h1 {
  font-family: 'Playfair Display', serif;
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
}

.about-us-header p {
  font-size: 1rem;
  color: #555;
  width: 50%;
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.grid-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0;
  width: 90%;
  min-height: 1000px;
  overflow: hidden;
}

.grid-item {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 500px;
  overflow: hidden;
  box-sizing: border-box;
}

/* Top-left (first grid item) */
.grid-item:nth-child(1) {
  border: 1px solid #000;
}

/* Top-right (second grid item) */
.grid-item:nth-child(2) {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: none;
}

/* Bottom-left (third grid item) */
.grid-item:nth-child(3) {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-top: none;
}

/* Bottom-right (fourth grid item) */
.grid-item:nth-child(4) {
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-top: none;
  border-left: none;
}

.image-column img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures image fills the grid */
}

.text-column {
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  overflow-wrap: break-word;
}

.text-column h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.text-column p, .text-column ul {
  font-size: 1rem;
  line-height: 1.5;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  color: #555;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

ul li {
  margin-bottom: 5px;
}

/* Ensuring equal size for text and image columns */
.grid-layout .grid-item {
  min-width: 0; /* Avoid overflow */
}

.grid-layout {
  grid-gap: 0;
}

@media (max-width: 1024px) {
  .image-column {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-column img {
    height: 400px;
    width: auto;
  }
}

@media (max-width: 890px) {
  .about-us-page {
    padding: 30px;
  }

  .grid-layout {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    min-height: auto;
  }

  .text-column, .image-column {
    padding: 0;
  }

  .image-column {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-column img {
    height: 300px;
    width: auto;
  }

  .text-column h2 {
    font-size: 1.6rem;
  }

  .about-us-header p {
    width: 70%;
  }

  .grid-item:nth-child(1),
  .grid-item:nth-child(2),
  .grid-item:nth-child(3),
  .grid-item:nth-child(4) {
    border: none;
  }

  .grid-item:nth-child(1) {
    order: 1;
  }

  .grid-item:nth-child(2) {
    order: 2;
  }

  .grid-item:nth-child(3) {
    order: 4;
  }

  .grid-item:nth-child(4) {
    order: 3;
  }
}

@media (max-width: 540px) {
  .grid-layout {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    width: 100%;
  }

  .about-us-page {
    padding: 20px;
  }

  .about-us-header h1 {
    font-size: 2.5rem;
  }

  .about-us-header p {
    width: 90%;
    font-size: 0.9rem;
  }

  .text-column {
    padding: 0;
    order: 1;
  }

  .image-column {
    padding: 0;
    order: 2;
  }

  .text-column h2 {
    font-size: 1.4rem;
  }

  .text-column p {
    font-size: 0.9rem;
  }

  .image-column img {
    height: 250px;
  }
}
