*,
*::before,
*::after {
  box-sizing: border-box;
}

.appt-container {
  background-color: #F5E9F7;
  max-width: 100%;
  padding: 8%;
  display: flex;
  justify-content: space-between;
}

.appt-container h1 {
  font-size: 2.5rem;
  margin-right: 60px;
}

.appt-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: justify;
  padding: 60px;
  background-color: rgba(256, 256, 256, 0.7);
  width: 100%;
  border-radius: 10px;
  font-family: 'Lato', sans-serif;
}

.appt-form-field {
  margin-bottom: 30px;
}

.appt-form-field label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.appt-form-field > p {
  margin-top: 0;
  font-size: 0.95rem;
}

.appt-form-field input,
.appt-form-field select,
.appt-form-field textarea {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
}

.appt-form-field textarea {
  max-width: 100%;
}

.appt-form-btn-caption {
  margin-bottom: 0;
  font-size: 0.9rem;
  color: #778798;
}

@media (max-width: 980px) {
  .appt-container {
    padding: 20px;
  }

  .appt-container h1 {
    font-size: 2rem;
    margin-right: 20px;
  }

  .appt-form {
    padding: 30px;
  }

  .appt-form-submit-btn {
    padding: 10px;
    margin-top: 20px;
  }
}

@media (max-width: 720px) {
  .appt-container {
    flex-direction: column;
    align-items: center;
  }

  .appt-container h1 {
    font-size: 1.8rem;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .appt-form {
    padding: 20px;
  }

  .appt-form-field input,
  .appt-form-field select,
  .appt-form-field textarea {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .appt-container h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .appt-form-field label {
    font-size: 0.9rem;
  }

  .appt-form-field > p {
    font-size: 0.85rem;
  }

  .appt-form-field input,
  .appt-form-field select,
  .appt-form-field textarea {
    padding: 8px;
  }

  .appt-form-btn-caption {
    font-size: 0.8rem;
  }

  .appt-form-submit-btn {
    padding: 8px;
  }
}
