.contact-us-page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px;
}

.contact-us-page-header h1 {
  margin: 30px auto;
}

.contact-us-page-header p {
  font-weight: 100;
  font-family: 'Lato', sans-serif;
  width: 40%;
  text-align: center;
  margin: 0 auto;
}

.contact-us-page-content {
  background-color: #f5f5f5;
  max-width: 100%;
  padding: 8%;
  display: flex;
  justify-content: center;
}

.contact-us-page-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 60px;
  background-color: rgba(201, 201, 201, 0.8);
  width: 60%;
  height: 60%;
  border-radius: 10px;
}

.contact-us-page-info {
  display: flex;
  justify-content: space-between;
  width: 70%;
  flex: 0 1 auto;
  flex-wrap: wrap;
}

.contact-us-page-info div {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.contact-us-page-fields-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 3% 5%;
}

.contact-us-page-form-field {
  width: 100%;
  max-width: 500px;
  margin: 20px !important;
}

@media (max-width: 880px) {
  .contact-us-page-header {
    padding: 30px 40px;
  }

  .contact-us-page-header h1 {
    font-size: 1.5rem;
  }

  .contact-us-page-header p {
    font-size: 0.9rem;
    width: 90%;
  }

  .contact-us-page-content {
    padding: 5%;
  }

  .contact-us-page-form {
    padding: 20px;
    width: 90%;
    height: auto;
  }

  .contact-us-page-info {
    flex-direction: column;
    align-items: center;
  }

  .contact-us-page-fields-container {
    padding: 2% 4%;
  }

  .contact-us-page-form-field {
    width: 100%;
  }

  .contact-us-page-form-submit-button {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .contact-us-page-header {
    padding: 20px;
  }

  .contact-us-page-header h1 {
    font-size: 1.2rem;
  }

  .contact-us-page-header p {
    font-size: 0.9rem;
    width: 95%;
  }

  .contact-us-page-content {
    padding: 3%;
  }

  .contact-us-page-info {
    width: 90%;
    align-items: flex-start;
    font-size: 0.9rem;
  }

  .contact-us-page-info div {
    align-items: center;
    text-align: center;
  }

  .contact-us-page-form {
    padding: 15px;
    width: 100%;
    height: auto;
  }

  .contact-us-page-fields-container {
    padding: 2% 2%;
  }

  .contact-us-page-form-submit-button {
    width: 90%;
  }
}
