.cart-container {
  margin: 50px auto;
  padding: 20px 40px;
  background: #f9f9f9;
  border-radius: 8px;
  width: 80%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
}

.cart-container h1 {
  font-size: 2rem;
  padding-left: 10px;
}

.cart-items {
  margin-top: 20px;
  flex: 1 1 auto;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
}

.cart-item-image {
  max-width: 140px;
  width: 20%;
  height: auto;
  object-fit: cover;
  margin-right: 20px;
}

.cart-item-info {
  flex: 1;
}

.cart-item-actions {
  border: 1px solid #CECFD3;
  display: inline-block;
  border-radius: 8px;
  background: #F9F9F9;
  margin-bottom: 20px;
  margin-right: 20px;
}

.cart-item-actions button {
  width: 30px;
  height: 30px;
  margin: 0 10px;
  font-size: 1rem;
  border: none;
  background: none;
}

.remove-item-btn {
  color: #e74c3c;
  background: none;
  border: none;
  cursor: pointer;
}

.cart-total-amount {
  font-size: 1.3rem;
  font-weight: 600;
  padding: 20px 0;
}

.checkout-btn {
  display: block;
  width: 90%;
  padding: 20px 10px;
  font-size: 1.1rem;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;
  align-self: center;
}

@media (max-width: 768px) {
  .cart-container {
    padding: 15px;
    width: 100%;
    margin: 0;
    background: white;
    box-sizing: border-box;
  }

  .cart-container h1 {
    font-size: 1.6rem;
  }

  .cart-items {
    width: 95%;
    margin: 20px auto 0 auto;
  }

  .cart-item {
    padding: 10px;
    background-color: #eaeaea;
  }

  .cart-item-image {
    width: 30%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .cart-item-info {
    width: 70%;
    padding: 10px 20px;
  }

  .cart-item-description {
    display: flex;
    justify-content: space-between;
  }

  .cart-item-actions {
    margin: auto 20px auto 0;
  }

  .cart-total-amount {
    font-size: 1.2rem;
  }

  .checkout-btn {
    width: 100%;
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .cart-container {
    padding: 10px;
    width: 100%;
    margin: 0;
    background: white;
    box-sizing: border-box;
  }

  .cart-container h1 {
    font-size: 1.4rem;
  }

  .cart-item {
    flex-direction: column;
    padding: 20px 10px 10px 10px;
    background-color: #eaeaea;
    align-items: center;
  }

  .cart-item-info {
    width: 90%;
    padding: 10px 0;
  }

  .cart-item-actions button {
    width: 25px;
    height: 25px;
    font-size: 0.9rem;
  }

  .cart-total-amount {
    font-size: 1.1rem;
    padding-left: 10px;
  }

  .checkout-btn {
    padding: 12px 0;
    font-size: 0.9rem;
  }
}

@media (max-width: 470px) {
  .cart-container h1 {
    font-size: 1.2rem;
  }

  .cart-items {
    width: 100%;
  }

  .cart-item-image {
    width: 50%;
    height: auto;
  }

  .cart-item-info {
    width: 95%;
  }

  .cart-item-info h3 {
    font-size: 1rem;
  }
}
