.iching-cards-explanations-page {
  padding: 50px 0;
  background-color: #EAEAEA;
}

.iching-cards-explanations-page-header {
  padding: 3%;
  min-height: 40%;
  text-align: center;
}

.iching-cards-explanations-page-header h1 {
  color: #24253A;
  width: 70%;
  margin: 30px auto;
  font-size: 2.5rem;
}

.iching-cards-explanations-page-header h3 {
  color: #24253A;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.iching-cards-explanations-content-container {
  padding: 60px 120px;
}

.iching-cards-explanations-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.iching-cards-explanations-page-card {
  width: 150px;
  margin: 0 auto;
}

.iching-cards-explanations-page-card:hover {
  cursor: pointer;
}

.iching-cards-explanations-page-card-image {
  width: 100%;
  transition: transform 0.5s ease;
}

.iching-cards-explanations-page-card-image.zoom-in {
  transform: scale(1.1); /* Image zoom effect */
}

.iching-cards-explanations-page-quick-view {
  display: flex;
  padding: 20px;
}

.iching-cards-explanations-page-quick-view-image-container {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
}

.iching-cards-explanations-page-quick-view-image-container img {
  width: 100%;
}

.iching-cards-explanations-page-quick-view-content {
  padding-left: 20px;
}

.iching-cards-explanations-page-quick-view-content h5 {
  font-weight: 400;
}

.iching-cards-explanations-page-search-bar-container {
  width: 40%;
  margin: 0 auto;
  padding: 0 30px;
}

.iching-cards-explanations-page-search-bar {
  background-color: white;
  width: 100%;
  font-size: 1rem;
  padding: 10px;
  border-radius: 0.7rem;
  border: none;
  font-family: 'Playfair Display';
  letter-spacing: 0.5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 1150px) {
  .iching-cards-explanations-page-header h1 {
    width: 80%;
    font-size: 1.8rem;
  }

  .iching-cards-explanations-page-header h3 {
    font-size: 1rem;
  }

  .iching-cards-explanations-content-container {
    padding: 40px;
  }

  .iching-cards-explanations-page-quick-view {
    padding: 10px;
  }

  .iching-cards-explanations-page-quick-view-image-container {
    width: 30%;
  }

  .iching-cards-explanations-page-quick-view-content {
    padding-left: 10px;
    margin-top: 20px;
  }

  .iching-cards-explanations-page-search-bar-container {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .iching-cards-explanations-page-header h1 {
    width: 80%;
    font-size: 1.8rem;
  }

  .iching-cards-explanations-page-header h3 {
    font-size: 1rem;
  }

  .iching-cards-explanations-content-container {
    padding: 40px;
  }

  .iching-cards-explanations-cards-container {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }

  .iching-cards-explanations-page-quick-view {
    flex-direction: column;
  }

  .iching-cards-explanations-page-quick-view-image-container {
    margin: 0 auto;
  }

  .iching-cards-explanations-page-quick-view-content {
    padding-left: 0;
    margin-top: 20px;
  }

  .iching-cards-explanations-page-quick-view-content h3 {
    font-size: 1rem;
  }

  .iching-cards-explanations-page-quick-view-content h5 {
    font-size: 0.8rem;
  }

  .iching-cards-explanations-page-quick-view-content p {
    font-size: 0.9rem;
  }

  .iching-cards-explanations-page-search-bar-container {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .iching-cards-explanations-page-header h1 {
    width: 80%;
    font-size: 1.5rem;
  }

  .iching-cards-explanations-page-header h3 {
    font-size: 0.9rem;
  }

  .iching-cards-explanations-content-container {
    padding: 20px;
  }

  .iching-cards-explanations-cards-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .iching-cards-explanations-page-quick-view {
    flex-direction: column;
    padding: 10px;
  }

  .iching-cards-explanations-page-quick-view-image-container {
    margin: 0 auto;
  }

  .iching-cards-explanations-page-quick-view-content {
    padding-left: 0;
    margin-top: 20px;
  }

  .iching-cards-explanations-page-quick-view-content h3 {
    font-size: 1rem;
  }

  .iching-cards-explanations-page-quick-view-content h5 {
    font-size: 0.8rem;
  }

  .iching-cards-explanations-page-quick-view-content p {
    font-size: 0.9rem;
  }

  .iching-cards-explanations-page-search-bar-container {
    width: 80%;
  }
}

@media (hover: none) {
  .iching-cards-explanations-page-card {
    cursor: default;
  }
}
