.checkout-payment-method p {
  margin: 5px 0;
}

.checkout-payment-method h4 {
  margin-bottom: 5px;
  text-decoration: underline;
}

.checkout-payment-payme-qr-code {
  width: 200px;
}

@media (max-width: 480px) {
  .checkout-payment-method p {
    font-size: 0.9rem;
  }

  .checkout-payment-payme-qr-code {
    width: 100px;
  }
}
