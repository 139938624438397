.classes-page {
  padding: 100px 50px;
}

.classes-page-header {
  color: #777;
  margin: 20px;
  width: 60%;
}

.classes-page-header h1 {
  font-size: 2rem;
  margin: 0 0 10px 0;
  color: black;
}

.classes-page-header p {
  font-size: 0.9rem;
  margin: 0;
  font-family: 'Lato', sans-serif;
}

.classes-timetable-container {
  color: #777;
  margin: 20px;
}

.classes-timetable-container p {
  font-size: 0.9rem;
  margin: 20px 0;
  font-family: 'Lato', sans-serif;
}

.facebook-embed-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Responsive iframe */
.facebook-embed {
  width: 100%;
  max-width: 500px;  /* Prevent it from being too large */
  height: 700px;
  border: none;
}

.classes-page-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 50px;
  padding: 20px;
  margin: 60px 0;
}

@media (max-width: 1250px) {
  .classes-page-list {
    gap: 30px;
  }

  .classes-page {
    padding: 50px 20px;
  }
}

@media (max-width: 820px) {
  .classes-page {
    padding: 40px 10px;
  }

  .classes-page-list {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }

  .classes-page-header {
    width: 80%;
  }

  .classes-page-header h1 {
    font-size: 1.5rem;
  }

  .classes-page-header p {
    font-size: 0.8rem;
  }
}

@media (max-width: 670px) {
  .classes-page {
    padding: 40px 5px;
  }

  .classes-page-list {
    grid-template-columns: minmax(250px, 1fr);
    gap: 60px;
  }

  .classes-page-header {
    width: 90%;
  }

  .classes-page-header h1 {
    font-size: 1.3rem;
  }

  .classes-page-header p {
    font-size: 0.8rem;
  }
}
