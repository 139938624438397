.details-view-container {
  margin: 0 auto 20px auto;
  max-width: 900px;
  padding: 100px 50px 50px 50px;
  width: 70%;
}

.details-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 50px
}

.details-view-carousel {
  width: 35%;
}

.carousel-image, .details-view-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: center;
}

/* Custom slick styling */
.slick-prev:before, .slick-next:before {
  color: #ff6600;
}

.slick-dots li button:before {
  color: #494949;
}

.slick-dots li.slick-active button:before {
  color: #ff6600;
}
/* End: Custom slick styling */

.details-view-info {
  width: 50%;
  margin: 0 50px;
}

.details-view-info h1 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: 200;
}

.details-view-price {
  display: flex;
  align-items: flex-start;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: fit-content;
}

.details-view-price p:nth-child(1) {
  border-right: 1px solid #ddd;
  margin-left: 10px;
  padding: 10px 10px 10px 5px;
}

.details-view-price p:last-child {
  margin-right: 10px;
  padding: 10px 5px 10px 10px;
}

.details-view-price p {
  font-size: 1rem;
  font-weight: 100;
  margin: 0;
}

.details-view-btn {
  padding: 10px 20px;
  font-size: 1rem;
  width: 100%;
}

.details-view-contacts {
  margin-top: 40px;
  border-top: 1px solid #ddd;
  padding: 20px;
}

.details-view-contact-info {
  width: 70%;
}

.details-view-contact-info p {
  font-size: 1rem;
  margin: 0;
}

@media (max-width: 980px) {
  .details-view-container {
    max-width: 100%;
    width: 90%;
  }
}

@media (max-width: 768px) {
  .details-view-container {
    max-width: 100%;
    width: 90%;
    margin: 0 auto;
    padding: 50px 20px;
  }

  .details-view {
    flex-direction: column;
    align-items: center;
  }

  .details-view-carousel, .details-view-info {
    width: 100%;
    margin-bottom: 20px;
  }

  .details-view-info {
    margin: 0;
  }

  .details-view-info h1 {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .details-view-container {
    max-width: 100%;
    width: 90%;
    margin: 0 auto;
    padding: 30px 10px;
  }

  .details-view {
    flex-direction: column;
    align-items: center;
  }

  .details-view-carousel {
    width: 100%;
  }

  .details-view-info {
    width: 100%;
    margin: 0;
  }

  .details-view-info h1 {
    font-size: 1.2rem;
  }

  .details-view-price p {
    font-size: 0.9rem;
  }

  .details-view-btn {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .details-view-contacts {
    padding: 10px;
    margin-top: 30px;
  }
}
