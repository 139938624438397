@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Playfair+Display:wght@400;700&family=Lato:wght@400;700&display=swap');

body {
  font-family: 'Lato', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
  letter-spacing: 1px;
}

h1 {
  font-size: 3rem;
}

.App {
  font-family: 'Avenir', sans-serif;
  color: #333;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
}

.App > *:not(footer) {
  flex-grow: 1;
}
