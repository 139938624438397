.confirmation-container {
  max-width: 100%;
  margin: 100px auto;
  padding: 30px;
  background: #f5f5f5;
  text-align: center;
  border-radius: 8px;
}

.confirmation-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.confirmation-container p {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.confirmation-container Button {
  margin-bottom: 20px;
}

.confirmation-container > .checkout-payment-method {
  text-align: left;
}

@media (max-width: 850px) {
  .confirmation-container {
    padding: 20px;
    margin: 60px auto;
    background-color: white;
  }

  .confirmation-container h1 {
    font-size: 2rem;
  }

  .confirmation-container p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .confirmation-container {
    padding: 15px;
    margin: 50px auto;
  }

  .confirmation-container h1 {
    font-size: 1.5rem;
  }

  .confirmation-container p {
    font-size: 1rem;
  }

  .confirmation-container Button {
    font-size: 0.9rem;
  }
}
